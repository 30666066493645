import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { createPO } from "./action";
import Input from "../../../component/input";
import { Box, Grid } from "@mui/material";
import Select from "../../../component/select";
import NormalButton from "../../../component/button";
const AddUser = ({ setOpen, isEdit, setIsEdit, storeId }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const [grandTotal, setGrandTotal] = useState(0);
  const initialValues = {
    product: [
      {
        product: null,
        productSellingPrice: "",
        productCostPrice: "",
        quantity: "",
        total: 0,
      },
    ],
    type: isEdit?.length ? isEdit[0]?.invoice : "",
    expiryDate: null,
    deliveryDate: null,
    store: 0,
    vendor: 0,
    terms: "",
    // vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
  };
  const calculateGrandTotal = (values, index, currentValue, currentField) => {
    const grandTotal = values.reduce((total, item, i) => {
      if (i === index) {
        if (currentField === "productCostPrice")
          total = +currentValue * values[index].quantity + total;
        else total = +currentValue * values[index].productCostPrice + total;
      } else {
        total = item.total + total;
      }
      return total;
    }, 0);
    setGrandTotal(grandTotal);
  };
  // const { storeList } = useSelector((state) => state.storeReducer);
  const {
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  // const { brandOwnerList } = useSelector((state) => state.brandOwnerReducer);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Schema.storeStockSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(createPO(values, setOpen, storeId, setSubmitting));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5">
            <Grid item xs={12} sm={6} lg={4}>
              <AutoComplete
                freeSolo={false}
                // onBlur={handleBlur}
                values={values.vendor}
                onChange={(event, value) => {
                  if (value !== null) {
                    const pIndex = brandOwnerListForDropdown.findIndex(
                      (x) => x.id === value.id
                    );
                    setFieldValue(`vendor`, value?.id);

                    // handleChange(event);
                  }
                }}
                helperText={touched?.vendor && errors?.vendor}
                error={Boolean(touched.vendor && errors?.vendor)}
                name={`vendor`}
                label={"Vendor"}
                // touched={touched.product}
                options={brandOwnerListForDropdown}
                getOptionLabel={(option) => `${option?.alias}`}
                // isRequired={true}
                getOptionSelected={(option, value) => {
                  return `${option.id}` === `${value.id}`;
                }}
                defaultValue={() => {
                  const pIndex = brandOwnerListForDropdown.findIndex(
                    (x) => x?.id == values.vendor
                  );
                  return brandOwnerListForDropdown[pIndex];
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                handleChange={handleChange}
                value={values.store}
                label={"Store Name"}
                option={
                  storeListForDropdown?.length &&
                  storeListForDropdown.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      {item?.storename}
                    </MenuItem>
                  ))
                }
                name="store"
                error={errors.store}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Input
                value={values.terms}
                name="terms"
                type="text"
                error={errors.terms}
                label="Terms"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* <Box sx={{ display: "flex", mb: 2 }}> */}

              <DatePicker
                value={values.deliveryDate}
                name="deliveryDate"
                handleChange={(newVal) => setFieldValue("deliveryDate", newVal)}
                label="Delivery Date"
                error={errors.deliveryDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <DatePicker
                value={values.expiryDate}
                name="expiryDate"
                handleChange={(newVal) => setFieldValue("expiryDate", newVal)}
                label="Expiry Date"
                error={errors.expiryDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Select
                handleChange={handleChange}
                value={values.type}
                label={"PO Type"}
                option={["one-time", "reccuring"].map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                name="type"
                error={errors.type}
              />
            </Grid>
          </Grid>
          <hr className="m-t-20 m-b-20" />
          <FieldArray
            name="product"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.product && values.product.length > 0
                    ? values.product.map((val, index) => (
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={2}
                          className="m-t-5"
                        >
                          <Grid item xs={8} sm={4} lg={3}>
                            <AutoComplete
                              freeSolo={false}
                              // onBlur={handleBlur}
                              values={values.product[index].product}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  const pIndex = productsDropDownList.findIndex(
                                    (x) => x.id === value.id
                                  );
                                  setFieldValue(
                                    `product.${index}.product`,
                                    value?.id
                                  );

                                  // handleChange(event);
                                  if (pIndex >= 0) {
                                  }
                                }
                              }}
                              helperText={
                                touched?.product &&
                                errors?.product &&
                                errors?.product[index]?.product
                              }
                              error={Boolean(
                                touched?.product &&
                                  errors?.product &&
                                  errors?.product[index]?.product
                              )}
                              name={`product.${index}.product`}
                              label={"Product Id"}
                              // touched={touched.product}
                              options={productsDropDownList}
                              getOptionLabel={(option) =>
                                `${option?.barcode} | ${option.name}`
                              }
                              // isRequired={true}
                              getOptionSelected={(option, value) => {
                                return `${option.id}` === `${value.id}`;
                              }}
                              defaultValue={() => {
                                const pIndex = productsDropDownList.findIndex(
                                  (x) => x?.id == values.product[index].product
                                );
                                return productsDropDownList[pIndex];
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} lg={2}>
                            <Input
                              value={values.product[index].quantity}
                              name={`product.${index}.quantity`}
                              type="number"
                              error={
                                errors.product &&
                                errors.product[index]?.quantity
                              }
                              label="Quantity"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `product.${index}.total`,
                                  +e.target.value *
                                    values.product[index].productCostPrice
                                );

                                calculateGrandTotal(
                                  values.product,
                                  index,
                                  e.target.value,
                                  "quantity"
                                );
                              }}
                              handleBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} lg={2}>
                            <Input
                              value={values.product[index].productSellingPrice}
                              name={`product.${index}.productSellingPrice`}
                              type="number"
                              error={
                                errors.product &&
                                errors.product[index]?.productSellingPrice
                              }
                              // error={errors.product}
                              label="Selling Price"
                              onChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} lg={2}>
                            <Input
                              value={values.product[index].productCostPrice}
                              name={`product.${index}.productCostPrice`}
                              type="number"
                              // error={errors.product}
                              error={
                                errors.product &&
                                errors.product[index]?.productCostPrice
                              }
                              label="Cost Price"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `product.${index}.total`,
                                  +e.target.value *
                                    values.product[index].quantity
                                );

                                calculateGrandTotal(
                                  values.product,
                                  index,
                                  e.target.value,
                                  "productCostPrice"
                                );
                              }}
                              handleBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} lg={2}>
                            <Input
                              value={values.product[index].total}
                              name={`product.${index}.total`}
                              type="number"
                              disabled={true}
                              error={
                                errors.product && errors.product[index]?.total
                              }
                              // error={errors.product}
                              label="Total"
                              onChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={3} sm={2} lg={1}>
                            <IconButton
                              style={{
                                background: "rgb(231 112 112)",
                                color: "white",
                                width: 35,
                                height: 35,
                                borderRadius: 5,
                                marginTop:12
                              }}
                              aria-label="delete"
                              size="small"
                              // color= "primary"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    : null}
                  <hr className="m-t-20 m-b-20" />
                  <Box
                    sx={{
                      display: "flex",
                      mb: 2,
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        pr: 2,
                        maxWidth: "100%",
                        textAlign: "right",
                      }}
                    >
                      Grand Total
                    </Box>

                    <Input
                      value={grandTotal}
                      disabled
                      name="grandTotal"
                      type="number"
                      // error={errors.batch}
                      label="Grand Total "
                    />

                    <IconButton
                      style={{
                        background: "green",
                        color: "white",
                        width: 35,
                        height: 35,
                        borderRadius: 5,
                        marginLeft: 10,
                      }}
                      aria-label="delete"
                      size="small"
                      // color= "primary"
                      onClick={() =>
                        arrayHelpers.push({
                          product: null,
                          quantity: null,
                          recievedQuantity: null,
                          productCostPrice: null,
                          total: 0,
                          landingPrice: null,
                        })
                      }
                    >
                      <AddBoxTwoToneIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </div>
              );
            }}
          />
          <hr className="m-t-20 m-b-20" />

          <div className="Dflex al-cnt fl-end">
            <NormalButton
              className="btn-simple"
              buttonText="Cancel"
              onPress={() => setOpen(false)}
            />
            <NormalButton
              className="btn-purple m-l-30"
              buttonText="Submit"
              // onPress={() => {}}
              type="submit"
            />
          </div>
          {/* <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit" disabled={isSubmitting}>
              {isEdit?.length ? "update GRN" : "Add PO"}
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
            >
              Cancel
            </Button>
          </Box> */}
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
