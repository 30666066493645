import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
import MenuItem from "@mui/material/MenuItem";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import moment from "moment";

import useDebounce from "../../../hooks/debounce";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";
import PoFilter from "./filter";
import {
  // getWarehouseStockListById,
  getPOById,
  getPOkList,
} from "./action";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import AddPoDrawer from "../../../component/drawer";
// import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import Modal from "../../component/modal"
import AddUser from "./addPo";
import Select from "../../../component/select";
import { getProducts } from "../../productRelated/products/action";
import {
  getBiller,
  getOprationManagerList,
  getStoreList,
} from "../store/action";
import { getBrandOwnerList } from "../../userAccount/brandOwner.js/action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PODetail from "./detailPO";
// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "PO Id",
  },
  {
    id: "warehouseNickname",
    numeric: false,
    disablePadding: true,
    label: "GRN Id",
  },

  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  // {
  //   id: "bin",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Bin",
  // },
  {
    id: "brand",
    numeric: false,
    disablePadding: true,
    label: "Vender Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Vender Name",
    sorting: true,
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Made By",
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Store ID",
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Store Name",
    sorting: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sorting: true,
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Total Value",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        PO
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [storeId, setStoreId] = React.useState(null);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [openPO, setOpenPO] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalRow, setOriginalRow] = useState([]);
  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const { POList, count, order, sortField, offset, take, search } = useSelector(
    (state) => state.POReducer
  );
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    dispatch(getPOkList());
  }, [debouncedSearch]);

  React.useEffect(() => {
    // dispatch(getWarehouseList());
    // dispatch(getProducts());
    dispatch(getStoreList());
    dispatch(getBrandOwnerList());
    // dispatch(getPOById("202281300000", 1));
    // dispatch(getPropertyOwner());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.PO,
      payload: { offset: newPage + 1 },
    });
    dispatch(getPOkList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.PO,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getPOkList());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.PO,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getPOkList());
    // setOrderBy(property);
  };
  // Avoid a layout jump when reaching the last page with empty rows.

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.PO,
      payload: { search: searchedVal, take: take, offset: 1 },
    });
  };
  const handleWarehouseSelect = (e) => {
    setStoreId(e.target.value);
    dispatch(getPOkList(e.target.value));
  };

  // const { brandOwnerList } = useSelector((state) => state.brandOwnerReducer);

  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);

  // const { products } = useSelector((state) => state.productsReducer);
  const {
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // warehouseListForDropdown,
  } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          {/* <Tooltip title="Please select store First"> */}
          <AddButton
            text="Create PO"
            className="btn-purple m-l-10"
            onPress={() => {
              setOpen(true);
            }}
          />
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              // clearSearch={handleSearchClear}
            />
            <FilterListIcon
              sx={{ fontSize: 40, cursor: "pointer" }}
              onClick={() => setOpenFilter(true)}
            />
          </Box>
          {/* </Tooltip> */}
        </div>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              // rowCount={warehouseStockLi st.length}
            />
            {/* {console.log(storeStockList, "storeStockList")} */}
            <TableBody>
              {POList &&
                POList?.length &&
                POList?.map((row, index) => {
                  return (
                    <TableRow hover key={row?.id}>
                      <TableCell component="th" id={index} scope="row">
                        {row?.poNumber || "N/A"}
                      </TableCell>
                      <TableCell align="left">{row?.grnId || "N/A"}</TableCell>
                      <TableCell align="left">{row?.date || "N/A"}</TableCell>
                      <TableCell align="left">
                        {row?.vendorId || "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.vendorName || "N/A"}
                      </TableCell>
                      <TableCell align="left">{row?.madeBy || "N/A"}</TableCell>
                      <TableCell align="left">{row?.store}</TableCell>
                      <TableCell align="left">{row?.storeName}</TableCell>
                      <TableCell align="left">{row?.type}</TableCell>
                      <TableCell align="left">{row?.status}</TableCell>
                      <TableCell align="left">{row?.totalValue}</TableCell>
                      <TableCell align="left">
                        <VisibilityIcon
                          onClick={() => {
                            dispatch(getPOById(row.poNumber, row?.store));
                            setOpenPO(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {/* <Modal
        header={`${isEdit ? "Edit" : "Add"} PO`}
        children={
          <AddUser
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            storeId={storeId}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={"80%"}
      /> */}
      <Modal
        header={"PO Detail"}
        children={
          <PODetail
            setOpenPO={setOpenPO}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        }
        handleClose={() => {
          setOpenPO(false);
          // setIsEdit(null);
        }}
        open={openPO}
        minWidth={1000}
      />
      <AddPoDrawer
        state={open}
        setState={setOpen}
        // className="notificationDrawer"
      >
        <>
          <div className="niti_header">
            <Typography variant="body2" className="semi-bold">
              Add PO
            </Typography>
            <hr className="m-t-20 m-b-20" />
          </div>
          <AddUser
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            storeId={storeId}
          />
        </>
      </AddPoDrawer>
      <AddPoDrawer
        state={openFilter}
        setState={setOpenFilter}
        // className="notificationDrawer"
      >
        <>
          <div className="niti_header">
            <Typography variant="body2" className="semi-bold">
              Filter
            </Typography>
            <hr className="m-t-20 m-b-20" />
          </div>
          <PoFilter
            setOpen={setOpenFilter}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </>
      </AddPoDrawer>
      {/* <DeleteConfirmationPopup
        title="Store Stock"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          // dispatch(deleteStoreStock(deleteConfirmation, storeId));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      /> */}
    </>
  );
}
